<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="提交时间" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 16)
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="审批编号" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onDetails(scope.row)"
                        >
                            {{ scope.row.declareNo || '- -' }}</el-button
                        >
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="部门" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div>
                        {{ returnName(scope.row) }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="员工" show-overflow-tooltip width="55">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName ? scope.row.adminName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.customer
                                ? scope.row.customer.companyName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="费用类型" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.declareType == 1
                                ? '订单报销费用'
                                : scope.row.declareType == 2
                                ? '月度报销费用'
                                : '- -'
                        }}
                    </div>
                </template>
                <!-- scope.row.contractTargetBO
                                ? scope.row.contractTargetBO
                                      .productAttributeRelationBOList &&
                                  scope.row.contractTargetBO
                                      .productAttributeRelationBOList.length > 0
                                    ? scope.row.contractTargetBO
                                          .productAttributeRelationBOList[0]
                                          .productName
                                    : '- -'
                                : scope.row.orderBO
                                ? scope.row.contractTargetBO
                                      .productAttributeRelationBOList &&
                                  scope.row.contractTargetBO
                                      .productAttributeRelationBOList.length > 0
                                    ? scope.row.contractTargetBO
                                          .productAttributeRelationBOList[0]
                                          .productName
                                    : '- -'
                                : '- -' -->
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="金额" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.amount ? scope.row.amount : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div>
                        {{ $cost.costStatus(scope.row.status) }}
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="20"> </el-table-column> -->
            <!-- <el-table-column label="操作" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onPay(scope.row)"
                        >支付</el-button
                    >
                </template>
            </el-table-column> -->
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
            btnP: {},
            pageData: {},
        };
    },
    components: {},
    created() {},
    methods: {
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        returnText(data, type, orderType = '') {
            let text = '';
            if (this.singleData.id) {
                this.singleData.productAttributeRelationBOList.forEach(
                    (item) => {
                        if (item.attributeColumnName == type) {
                            item.productAttributeValueBOList.forEach((itm) => {
                                if (orderType) {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[orderType]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                } else {
                                    if (
                                        itm.attributeValue ==
                                        this.singleData[type]
                                    ) {
                                        text = itm.attributeValueName;
                                    }
                                }
                            });
                        }
                    }
                );
            }
            return text;
        },
        getData(data) {
            this.tableData = data;
        },

        onDetails(row) {
            this.$emit('onDetails', row);
        },
        // onPay(row) {
        //     this.$emit('onPay', row);
        // },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
